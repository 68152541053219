<template>
    <v-container grid-list-xl fluid>
        <h1>Meus cartões</h1>
        <v-divider class="mb-4" />

        <v-alert outlined class="mt-12" color="red" prominent border="top" icon="info" v-if="items.length == 0"> Você não possui nenhum cartão associado ao seu usuário. </v-alert>

        <v-row dense>
            <v-col cols="12" v-for="item in items" :key="item.id">
                <v-card class="mx-auto" color="secondary" dark>
                    <v-card-title>
                        <v-icon large left> {{ getIcon(item.type) }} </v-icon>
                        <span class="text-h6 font-weight-light">{{ item.code }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-alert shaped color="error" icon="warning" v-if="item.status == 'BLOCKED'"> Bloqueado para uso </v-alert>
                    </v-card-text>

                    <v-card-actions>
                        <v-list-item class="grow">
                            <v-row align="center" justify="end">
                                <v-btn class="mr-2" outlined @click="unlock(item.id)" v-if="item.status == 'BLOCKED'"> Desbloquear </v-btn>
                                <v-btn class="mr-2" outlined @click="lock(item.id)" v-else> Bloquear </v-btn>

                                <v-btn icon @click="deleteCard(item.id)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </v-row>
                        </v-list-item>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <!-- <v-list subheader two-line v-else>
            <v-list-item class="tile" dark v-for="item in items" :key="item.id">
                <v-list-item-avatar active value="true">
                    <v-icon> {{ getIcon(item.type) }} </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title v-text="item.code"></v-list-item-title>

                    <v-list-item-subtitle class="mt-4">
                        <v-btn small color="warning" v-if="item.status == 'BLOCKED'" @click="unlock(item.id)" :loading="saving" :disable="saving"
                            >Bloqueado!!! Clique para liberar o uso.</v-btn
                        >
                        <v-btn small color="success" v-else @click="lock(item.id)" :loading="saving" :disable="saving">Pronto para uso! Clique para bloquear.</v-btn>
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-btn color="grey lighten-2" fab @click="deleteCard(item.id)">
                        <v-icon color="error">delete</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list> -->
        <ConfirmModal ref="confirm" />
    </v-container>
</template>

<script>
import ConfirmModal from "@/components/core/ConfirmModal";
import rules from "@/helpers/rules";

export default {
    mounted() {
        this.getCards();
    },

    components: {
        ConfirmModal,
    },

    data() {
        return {
            rules,
            saving: false,
            items: [],
        };
    },

    methods: {
        getIcon(type) {
            if (type === "CARD") return "credit_card";
            else if (type === "CAR") return "electric_car";
            else return "question_mark";
        },
        getCards() {
            this.$http
                .get("api/v2/mycards")
                .then((result) => {
                    this.items = result;
                })
                .catch(() => {
                    this.items = [];
                });
        },
        deleteCard(id) {
            this.$refs.confirm.open("Tem certeza?", "Você não poderá mais utilizar esse cartão. Deseja continuar?").then((confirm) => {
                if (confirm) {
                    this.$http
                        .delete("api/v2/mycards", { id: id })
                        .then(() => {
                            this.getCards();
                        })
                        .catch((error) => {
                            this.$eventHub.$emit("msgError", error.message ? error.message : "Error ao processar requisição.");
                        });
                }
            });
        },
        changeStatus(id, status) {
            this.$http
                .patch("api/v2/mycards", { id, data: { status } })
                .then(() => {
                    this.getCards();
                })
                .catch(() => {
                    this.getCards();
                });
        },
        unlock(id) {
            this.changeStatus(id, 1);
        },
        lock(id) {
            this.changeStatus(id, 0);
        },
    },
};
</script>

<style scoped>
.tile {
    margin: 5px;
    border-radius: 4px;
    background: var(--v-secondary-base);
}
</style>